import styled from 'styled-components'
import Button from '../Button'
import SpatialShimmerImage from "../../assets/spatialShimmerGUI.png";
import React, { useRef } from 'react';
import { useScrollAppearTransition } from '../ScrollingHooks';


const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.body};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
`

const Container = styled.div`
width: 100%;
margin: 0 auto;
display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;
}
@media (max-width: 40em){
  &>*:last-child{
    width: 90%;
  }
}
`

const Box = styled.div`
width: 60%;
height: 100%;
min-height: 60vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
opacity: 0.1; /* initially hidden */
transform: translateY(100px); /* initially moved down */
transition: opacity 0.8s, transform 0.8s; /* transition settings */
...

@media (max-width: 40em){
  min-height: 50vh;
}
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
  margin: 2rem auto 0 auto; // This adds a 2rem margin at the top
@media (max-width: 64em){
  width: 100%;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};
}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};
}
`
const SubText = styled.p`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  text-align: center;
  width: 80%;
  margin: 1rem auto;
  font-weight:400;
@media (max-width: 64em){
  width: 100%;
  font-size: ${(props) => props.theme.fontmd};
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};
}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};
}
`

const ButtonContainer = styled.div`
 width: 80%;
 margin: 1rem auto;
 display: flex;
 justify-content: center;
`

const ImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  // opacity: 1;
  // box-shadow: 5px 5px 15px rgba(256, 256, 256, 0.9);
  z-index: 1;
  img {
    width: 100%;
    // border-radius: 4%;

    // height: auto;
    // transition: width 0.5s ease-in-out;
  }
`;

const LatestRelease = () => {

const BoxRef = useRef(null);

useScrollAppearTransition(BoxRef);



  return (
    <Section id="latestRelease">
      <Container>
        <Box ref={BoxRef}> 
          <ImgContainer>
            <img src={SpatialShimmerImage} alt="Spatial Shimmer" />
          </ImgContainer>
          <Title>
            Latest Release
          </Title>
          <SubText>
           Spatial Shimmer
          </SubText>
          <ButtonContainer>
          {/* <ThemeProvider theme={dark}> */}
          <Button text="Find Out More" link="/spatial-shimmer" />
          {/* </ThemeProvider> */}
                </ButtonContainer>
        </Box>
      </Container>
    </Section>
  )
}

export default LatestRelease
