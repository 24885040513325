

import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import { ThemeProvider } from "styled-components";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import LandingPage from "./components/sections/LandingPage";
import Loading from './components/Loading';
import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useLocation } from "react-router-dom"
import ProductsPage from "./components/sections/Products";
import PrivacyPolicy from "./components/sections/PrivacyPolicy";
import TermsAndConditions from "./components/sections/TermsAndConditions";
import 'firebase/analytics';
import CookiesPolicy from "./components/sections/CookiesPolicy";

function App() {
  const location = useLocation();

  useEffect(() => {
    // Send pageview event to GA every time the route changes
    if (window.gtag) {
      window.gtag('config', 'G-XFKT3S9MRC', {
        'page_path': location.pathname + location.search
      });
    }
  }, [location]);
  
  return (
    <main>
      <GlobalStyles />
      <ThemeProvider theme={dark}>
        <Suspense fallback={<Loading />}>
        <Navigation />

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/spatial-shimmer" element={<ProductsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
        </Routes>

        <Footer />
        {/* <ScrollToTop />{" "} */}
        </Suspense>
      </ThemeProvider>
    </main>
  );
}

export default App;

