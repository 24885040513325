import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';



const WaveformContianer = styled.div`
  display: flex;  
  flex-direction: row;  
  align-items: center;
  justify-content: center;
  height: 100%;  width: 90%;
  background: transparent;
// background: #0019FF;
  margin: 1em 0;

  @media (max-width: 48em) {
    margin: 0.5em 0;
  }
`;

const Wave = styled.div`
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.button`
    display: flex;
    flex-shrink: 0;
    font-size: 9pt;
    justify-content: center;
    align-items: center;
    width: 3vw; 
    height: 3vw;
    max-width: 36px;
    max-height: 36px;
    min-width: 33px;
    min-height: 33px;
    border-radius: 50%;

    position: relative;

    border: 1px solid #797979;
    opacity: 0.7;
    background: #2C2C2C;
    color: rgba(255, 255, 255, 0.80);

    outline: none;
    cursor: pointer;
    margin-right: 10%;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; // Smooth transition for transform, background-color, and box-shadow

  &:hover {
    // background: #2FB8FF;
    background: linear-gradient(180deg, rgba(158, 236, 217, 1) 0%, rgba(47, 184, 255, 1) 100%);
    color: #FFF;
    // transform: scale(1.05); // Slightly enlarges the button on hover
    box-shadow: 0px 0px 10px 3px rgba(47, 184, 255, 0.3); // Adds a soft shadow beneath the button
  }

  @media (max-width: 48em) {
    margin: 5%;
  }
`;




const WaveformPlayer = ({ audioUrl, id }) => {
    const { playingAudioId, playAudio, pauseAudio } = useAudioPlayer();
    const [playing, setPlaying] = useState(false);
    const waveformRef = useRef(null);
    const wavesurferInstance = useRef(null);
    const trackRef = useRef(null);
  
    useEffect(() => {
        if (waveformRef.current && !wavesurferInstance.current) {
            // Create a canvas gradient
            const ctx = document.createElement('canvas').getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, waveformRef.current.offsetWidth, 0); // Gradient spans horizontally
            gradient.addColorStop(0, '#2FB8FF'); // Start color
            gradient.addColorStop(1, '#9EECD9'); // End color

            wavesurferInstance.current = WaveSurfer.create({
                barWidth: 3,
                cursorWidth: 1,
                container: waveformRef.current,
                backend: 'WebAudio',
                height: 80,
                progressColor: gradient, // Use the gradient as progressColor
                responsive: true,
                waveColor: '#303030',
                cursorColor: 'transparent',
            });
    
            wavesurferInstance.current.load(audioUrl);
    
            // Cleanup on component unmount
            return () => {
                wavesurferInstance.current && wavesurferInstance.current.destroy();
            };
        }
    }, [audioUrl]);
  
    const handlePlay = () => {
        if (playing) {
          wavesurferInstance.current.pause();
          pauseAudio();
          setPlaying(false);
        } else {
          if (playingAudioId) {
            // If another audio is playing, pause it
            document.getElementById(playingAudioId).pause();
          }
          wavesurferInstance.current.play();
          playAudio(id);
          setPlaying(true);
        }
      };
    
      useEffect(() => {
        if (playingAudioId !== id && playing) {
            wavesurferInstance.current.pause();
            setPlaying(false);
        }
    }, [playingAudioId, id, playing]);
    
  
    return (
      <WaveformContianer>
        <PlayButton onClick={handlePlay}>
        {!playing ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
        </PlayButton>
        <Wave ref={waveformRef} />
        <audio ref={trackRef} src={audioUrl} id={id} />
      </WaveformContianer>
    );
};



  
  const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-shadow: 2px 0px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);  // for iOS Safari support
  // background: #0019FF;

  @media (max-width: 48em) {
    width: 80%;
    // justify-content: center;
  }
`;

const MainTitle = styled.h1`
font-size:  24px;
  margin: 5%;
  color: ${(props) => props.theme.text};
  opacity: 0.9;

  @media (max-width: 48em) {
    margin: 0.5em;
  }

`;

const SubTitle = styled.p`
  font-size:  ${(props) => props.theme.fontsm};
color: ${(props) => props.theme.text};
opacity: 0.5;



`;

const BeforeAndAfterWaveform = ({ trackUrlBefore, trackBeforeID, trackUrlAfter, trackAfterID, title}) => {
  return (
    <Container>
      <MainTitle>{title}</MainTitle>

      <SubTitle>Before</SubTitle>
      <WaveformPlayer audioUrl={trackUrlBefore} id={trackBeforeID} />

      <SubTitle>After</SubTitle>
      <WaveformPlayer audioUrl={trackUrlAfter} id={trackAfterID} />
    </Container>
  );
};



const AudioPlayerContext = createContext();

const useAudioPlayer = () => {
  return useContext(AudioPlayerContext);
};

const AudioPlayerProvider = ({ children }) => {
  const [playingAudioId, setPlayingAudioId] = useState(null);

  const playAudio = (id) => {
    setPlayingAudioId(id);
  };

  const pauseAudio = () => {
    setPlayingAudioId(null);
  };

  return (
    <AudioPlayerContext.Provider value={{ playingAudioId, playAudio, pauseAudio }}>
      {children}
    </AudioPlayerContext.Provider>
  );
};



export { WaveformPlayer, BeforeAndAfterWaveform, AudioPlayerProvider};