import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'
import Loading from '../Loading';
import head from '../../assets/head4.png'
import Header from '../HeaderAnimation';


const CoverVideo = lazy(() => import('../CoverVideo'));
const TypeWriterText = lazy(() => import('../TypeWriterText'));

const Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})`   };
width: 100vw;
position: relative;
background-color: ${props => props.theme.body};
`
const Container = styled.div`
width: 75%;
min-height: 80vh;
margin: 0 auto;
/* background-color: lightblue; */
// border-bottom: 1px solid ${(props) => props.theme.text};

display: flex;
justify-content: center;
align-items: center;

@media (max-width: 64em) {
  width: 85%;
}
@media (max-width: 48em) {
  flex-direction: column-reverse;
  width: 100%;
  &>*:first-child{
    width: 100%;
    margin-top: 2rem;
  }
}
`
const ImgContainer = styled.div`
display: flex;
justify-content: center;
// align-items: center;
opacity: 1;
// box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1;
width: 100%;

img{
    width: 100%;
    height: auto;
    padding: 1em;
}

@media (max-width: 48em){
  width: 100%;
}
`
const Box = styled.div`
width: 50%;
height: auto;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 48em) {
  
  width: 80%;
}

`

const Home = () => {
  return (
    <Section id="home">
      <Container>

        <Box>
        <Suspense fallback={<Loading />}>
          <TypeWriterText /></Suspense>
        </Box>
        <Box>
        <Suspense fallback={<Loading />}>
          </Suspense>
          <ImgContainer>
            <img src={head} alt={`head`} />
          </ImgContainer>
        </Box>
      </Container>

    </Section>
    
  )
}

export default Home