import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoImage from "../assets/logo.png";


const Image = styled.img`
display: flex;
// background-color: green;
height: 3em;

transition: all 0.2s ease;
&:hover{
    transform: scale(1.1);
}


@media (max-width: 48em){
  height: 2.6em;

  }
`

// const LogoText = styled.h1`
// font-family: 'IBM Plex Mono', cursive;
// font-size: ${props => props.theme.fontxl};
// color: ${props => props.theme.text};
// transition: all 0.2s ease;

// &:hover{
//     transform: scale(1.1);
// }

// @media (max-width: 64em){
// font-size: ${props => props.theme.fontxxl};

// }
// `

const Logo = () => {
  return (
    // <Image src={LogoImage} alt="logo" >
    //     <Link to="/">
    //     </Link>
    // </Image>

    <Link to="/">
    <Image src={LogoImage} alt="logo" />
    </Link>

  )
}

export default Logo