import Home from "./Home";
import About from "./About";
import LatestRelease from "./LatestRelease";
import Faq from "./Faq";
import styled from 'styled-components'

const Section = styled.section`
min-height: ${props => `calc(100vh - ${props.theme.navHeight})`   };
width: 100vw;
position: relative;
background-color: ${props => props.theme.body};
`

// const Container = styled.div`
//   width: 75%;
//   margin: 0 auto;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media (max-width: 70em) {
//     width: 85%;
//   }
//   @media (max-width: 64em) {
//     width: 100%;
//   }
// `;

const Divider = styled.div`
  // width: 10%;
  height: 1px;  // Change this for a thicker or thinner line.
  background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};

  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

 
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const Content = () => {
  return (
    <Section id="mainContent">
      <Home />
      <Divider/>
      <LatestRelease />
      <Divider/>
      <About />
      <Divider/>
      <Faq />
      {/* <ScrollToTop />{" "} */}
    </Section>
  );
};
export default Content;
