import { createGlobalStyle } from "styled-components";
import '@fontsource/ibm-plex-mono';
import '@fontsource/dm-sans';
import "@fontsource/sora";
import "@fontsource/inter";



// [100,200,300,400,500,600,700,800]




const GlobalStyles = createGlobalStyle`

${'' /* 
*{
    outline: 1px solid red !important;
} */}

.show {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  
*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body{

    font-family: 'Inter';
    font-weight: normal;

    overflow-x: hidden;
    line-height: 1.4;

}

h1,h2,h3,h4,h5,h6, p{

    // font-family: 'DM Sans', sans-serif;
    font-family: 'Inter';
    line-height: 1.2;
    font-weight: normal;


    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}
`

export default GlobalStyles;