import React from 'react';
import styled, { keyframes } from 'styled-components';

const changeColor = keyframes`
  0% {
    background-color: #252729;
  }
  50% {
    background-color: #2FCDFF;
  }
  100% {
    background-color: #252729;
  }
`;

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100px;  // Adjust as per your design
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${changeColor} 5s infinite;
`;

const HeaderText = styled.h1`
  font-size: 2rem; // Adjust as per your design
  color: white;
`;

const Header = () => (
  <HeaderWrapper>
    <HeaderText>
      
    </HeaderText>
  </HeaderWrapper>
);

export default Header;
