import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, {useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';
import { useScrollAppearTransition } from '../ScrollingHooks';



const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
color: ${(props) => props.theme.text};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  
  margin: 1rem auto;
  // border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }

@media (max-width: 48em){
width: 90%;
flex-direction: column;
align-items: center;


  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
opacity: 0.1; /* initially hidden */
transform: translateY(100px); /* initially moved down */
transition: opacity 0.8s, transform 0.8s; /* transition settings */

// background: #fff;

@media (max-width: 48em){
  flex-direction: column;
  width: 90%;
  // align-self: center;
  }

`
const BodyText = styled.body`
  color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
`;

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);

const BoxRef1 = useRef(null);
const BoxRef2 = useRef(null);

useScrollAppearTransition(BoxRef1);
useScrollAppearTransition(BoxRef2);


  return (
    <Section ref={ref} id="faq">
    <Title>Faq</Title>

    <Container>

    <Box ref={BoxRef1}>
      <Accordion ScrollTrigger={ScrollTrigger} title="What formats do you support?">
        <BodyText>Our plugins come in both VST3 and AU formats. These common formats ensure compatibility with a broad range of audio production software.</BodyText> 
      </Accordion>
      <Accordion ScrollTrigger={ScrollTrigger} title="My DAW doesn't recognize the installed plugins. What should I do?">
      <BodyText>BodyText No worries! Occasionally, a DAW might not immediately recognize new plugins. First, ensure the plugin files are in the correct location where your DAW scans for plugins as specified in the user manual. Then, restart your DAW and perform a manual rescan if needed. If you're still encountering difficulties, please don't hesitate to contact <a href="mailto:support@sonaradsp.com" style={{textDecoration: "underline"}}>support.</a></BodyText>
      </Accordion>
    </Box>
    <Box ref={BoxRef2}>
      <Accordion ScrollTrigger={ScrollTrigger} title="What DAWs do you support?">
      <BodyText>We aim to provide wide-ranging compatibility for our users. Our plugins support most digital audio workstations (DAWs) that facilitate AU and VST3 formats. This includes, but is not limited to, popular platforms such as Logic Pro, REAPER, FL Studio, Cakewalk and more.</BodyText>
      </Accordion>
      <Accordion ScrollTrigger={ScrollTrigger} title="Do I get free updates for future versions after my purchase?">
      <BodyText>Absolutely! We believe in continuous improvement and innovation. Your initial purchase includes not only the current version of the plugin but also free updates for that plugin. This way, you'll always have access to our latest features and enhancements.</BodyText>
      </Accordion>
    </Box>

   

    </Container>
    </Section>
  )
}

export default Faq