import styled from 'styled-components'
import Logo from './Logo'
import React, { useState, Suspense } from "react";
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
import * as Scroll from "react-scroll";


import Facebook from '../Icons/Facebook'
import Instagram from '../Icons/Instagram'
import Twitter from '../Icons/Twitter'
import LinkedIn from '../Icons/LinkedIn'
import Loading from './Loading'




const Section = styled.section`
min-height: 30vh;
width: 100vw;
background-color: ${props => props.theme.body};
position: relative;
color: ${(props) => props.theme.text};

display: flex;
/* justify-content: center; */
/* align-items: center; */
flex-direction: column;
`

const Container = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;
// padding: 1rem 0; 


border-bottom: 1px solid ${(props) => props.theme.text};

@media (max-width: 48em) {
width: 90%;

h1{
font-size: ${props => props.theme.fontxxxl};

}
}
`
const Left = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

@media (max-width: 48em) {
// width: 100%;
}
`

const IconList = styled.div`
display: flex;
align-items: center;
// justify-content: left;


margin: 0.5rem auto;

&>*{
  padding-right: 0.5rem;
  transition: all 0.2s ease;

  &:hover{
    transform: scale(1.2);
  }
}
`
const MenuItems = styled.ul`
list-style:none;
width: 50%;
display: grid;
grid-template-columns: repeat(2, 1fr);
grid-template-rows: repeat(3, 1fr);
grid-gap: 1rem;
padding: 1rem 0; 

@media (max-width: 48em) {
// display: none;
}
`
const Item = styled.li`
width: fit-content;
cursor: pointer;

&::after{
  content: ' ';
  display: block;
  width: 0%;
  height: 2px;
  background: ${props => props.theme.text};
  transition: width 0.3s ease;
}
&:hover::after{
  width: 100%;
}
`

const Bottom = styled.div`
width: 75%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;
padding: 1rem 0; 
color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
font-size: ${props => props.theme.fontsm};


a{
  text-decoration:underline;
}
@media (max-width: 48em) {
flex-direction: column;
width: 90%;

span{
  margin-bottom: 1rem;

}
}
`;

const LegalLinks = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  
  a {
    text-decoration: underline;
  }

  @media (max-width: 48em) {
    justify-content: center;
  }
`;


const Footer = () => {

  // const scrollTo = (id) => {

  //   let element = document.getElementById(id);
  
  //   element.scrollIntoView({
  //     behavior: 'smooth',
  //     block: 'start',
  //     inline: 'nearest'
  //   })
  // }
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  const navigate = useNavigate();
  const [click, setClick] = useState(false);
  const scroller = Scroll.scroller;


  const goToPageAndScroll = async (selector) => {
    await navigate("/");
    await scroller.scrollTo(selector, {
      duration: 500,
      smooth: true,
      offset: 0,
      spy: true
    });
  };

  const scrollTo = (id) => {
    let element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setClick(!click);
  };

  
  return (
    <Section>
      
<Suspense fallback={<Loading />}>
{/* <Banner /> */}
 </Suspense>

    <Container>
    <Left> 
    <Logo />
    <IconList>

    <a href="https://www.instagram.com/sonaradsp/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='instagram'
      >
        <Instagram />
      </a>
      {/* <a href="https://www.linkedin.com/in/test/" target='_blank' 
      rel="noopener noreferrer"
      aria-label='linkedin'
      >
        <LinkedIn />
      </a> */}



      <a href="https://www.facebook.com/profile.php?id=61550461067503" target='_blank' 
      rel="noopener noreferrer"
      aria-label='facebook'
      >
        <Facebook />
      </a>
      
      {/* <a href="https://twitter.com/test" target='_blank' 
      rel="noopener noreferrer"
      aria-label='twitter'
      >
        <Twitter />
      </a> */}
      
    </IconList>
    </Left>
    
    <MenuItems>
    {location == "home" ? (
            <>
          <Item onClick={() => scrollTo('home')}  >Home</Item>
          <Item onClick={() => scrollTo('about')}  >About</Item>
          <Item onClick={() => scrollTo('latestRelease')}  >Latest Release</Item>
          <Item onClick={() => scrollTo('faq')}  >FAQ</Item>
          <Item >
          <RouterLink to="/spatial-shimmer">Products</RouterLink>
          </Item>
          <Item><a href="mailto:support@sonaradsp.com">Contact</a></Item>
          </>
          ) : (
            <>
          <Item onClick={() => goToPageAndScroll('home')}  >Home</Item>
          <Item onClick={() => goToPageAndScroll('about')}  >About</Item>
          <Item onClick={() => goToPageAndScroll('latestRelease')}  >Latest Release</Item>
          <Item onClick={() => goToPageAndScroll('faq')}  >FAQ</Item>
          <Item >
          <RouterLink to="/spatial-shimmer">Products</RouterLink>
          </Item>
          <Item><a href="mailto:support@sonaradsp.com">Contact</a></Item>
        </>
      )}

    </MenuItems>
      </Container>
        <Bottom>
          <span>
          &copy; {new Date().getFullYear()} Sonara DSP Ltd. All rights reserved. Company number 14950978.
          </span>
          <LegalLinks>
            <RouterLink onClick={(e) => { e.preventDefault(); window.displayPreferenceModal(); }}> Consent Preferences</RouterLink>
            <RouterLink to="/terms-conditions">Terms & Conditions</RouterLink>
            <RouterLink to="/privacy-policy">Privacy Policy</RouterLink>
            <RouterLink to="/cookies-policy">Cookies Policy</RouterLink>
          </LegalLinks>
        </Bottom>
    </Section>
  )
}

export default Footer
