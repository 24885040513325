import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';


export function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const useScrollAppearTransition = (BoxRef) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          BoxRef.current.classList.add('show');
        } else {
          BoxRef.current.classList.remove('show');
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );
    if (BoxRef.current) {
      observer.observe(BoxRef.current);
    }

    return () => observer && observer.disconnect();
  }, [BoxRef]);
}



