import styled from 'styled-components'
import React, { useRef, useEffect, useState } from 'react';
import videoSrc from "../../assets/laptop_rotation.mp4";
import { useScrollAppearTransition } from '../ScrollingHooks';


const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.body};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

`
const Container = styled.div`
width: 75%;
// margin: 0 auto;
/* background-color: lightblue; */

display: flex;
justify-content: center;
align-items: center;
@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`

const Box = styled.div`
  width: 50%;
  height: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.1; /* initially hidden */
  transform: translateY(100px); /* initially moved down */
  transition: opacity 1.0s, transform 1.0s; /* transition settings */
  ...
`;

 
const Title = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: capitalize;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  width: 80%;
  margin: 0 auto;
  // font-weight: 1; // Change this value as per your needs


@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontxl};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontlg};

}
`
const SubText = styled.body`
  font-size: ${(props) => props.theme.fontlg};
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  // font-weight: 1;
@media (max-width: 64em){
  width: 100%;
  text-align:center;
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 40em){
  font-size: ${(props) => props.theme.fontmd};

}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontsm};

}

`
const SubTextLight = styled.body`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
  align-self: flex-start;
  width: 80%;
  margin: 1rem auto;
  // font-weight: 1;

@media (max-width: 64em){
  width: 100%;
  text-align:center;
}
@media (max-width: 48em){
  font-size: ${(props) => props.theme.fontsm};
}
@media (max-width: 30em){
  font-size: ${(props) => props.theme.fontxs};
}
`

const VideoContainer = styled.video`
  max-width: 100%;
  height: auto;
  object-fit: cover;
  // padding: 2em 0;


  display: flex;
  justify-content: center;
  opacity: 1;
  z-index: 1;
  width: 50%;


  @media (max-width: 48em){
    padding: 2em 0;

    width: 75%;
  }

`;

const AutoPlaySilentVideo = (props) => {
  const videoRef = useRef();
  
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    video.addEventListener('loadedmetadata', (event) => {
      props.setVideoDuration(video.duration);
    });

    video.addEventListener('play', () => {
      video.pause();
    });

    return () => {
      video.removeEventListener('play', () => {
        video.pause();
      });
    }
  }, []);

  useEffect(() => {
    if(videoRef.current && Number.isFinite(props.scrollPercentage * props.videoDuration)) {
      videoRef.current.currentTime = props.scrollPercentage * props.videoDuration;
      // Make sure to play the video again when scroll percentage changes
      if(videoRef.current.paused) {
        // videoRef.current.play();
      }
    }
  }, [props.scrollPercentage, props.videoDuration]);

  return (
    <VideoContainer ref={videoRef} loop muted playsInline autoPlay>
      <source src={props.videoSrc} type="video/mp4" />
    </VideoContainer>
  );
}


const About = () => {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const BoxRef = useRef(null);

  useScrollAppearTransition(BoxRef);

  useEffect(() => {
      const handleScroll = () => {
          const aboutSection = document.getElementById("about");
          const aboutHeight = aboutSection.clientHeight;
          const scrollPos = aboutSection.getBoundingClientRect().top;

          if (aboutHeight === 0) {
              return;
          }

          const percentScrolled = Math.abs(scrollPos) / aboutHeight;
          setScrollPercentage(percentScrolled);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
      <Section id="about">
          <Container>
              <AutoPlaySilentVideo 
                  videoSrc={videoSrc} 
                  scrollPercentage={scrollPercentage} 
                  setVideoDuration={setVideoDuration}
                  videoDuration={videoDuration}
              />

      <Box ref={BoxRef}> 
       <Title>
      Elevate Your Sound
      </Title>
       <SubText>
       At Sonara, we're innovating the landscape of audio production. Our mission is to deliver state-of-the-art spatial audio mixing tools that are not only powerful but also user-friendly.
       </SubText>
        <SubTextLight>
        We believe that everyone should have access to the exciting world of spatial audio, no matter their level of technical expertise. Say goodbye to complex jargon and intimidating interfaces. With Sonara, spatial audio is made accessible to all — from professional sound engineers to bedroom producers. Join us as we redefine the boundaries of sound and open up a new dimension of audio experience.
       </SubTextLight>
        </Box>
     </Container>

    </Section>
  )
}

export default About;