
import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import Button from "../Button";
import SpatialShimmerImage from "../../assets/spatialShimmerGUI.png";
import synthBefore from "../../assets/synthBefore.mp3"
import synthAfter from "../../assets/synthAfter.mp3"
import voiceBefore from "../../assets/voxBefore.mp3"
import voiceAfter from "../../assets/voxAfter.mp3"
import pianoBefore from "../../assets/pianoBefore.mp3"
import pianoAfter from "../../assets/pianoAfter.mp3"
import AnimatedHeader from "../HeaderAnimation";
import { useScrollToTop } from '../ScrollingHooks';
import ReactPixel from 'react-facebook-pixel';
import {BeforeAndAfterWaveform, AudioPlayerProvider } from '../WaveformPlayer';
import {Helmet} from "react-helmet";





// Divider styled component
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.text};
`;

// Section styled component
const Section = styled.section`
  min-height: calc(100vh - ${({ theme }) => theme.navHeight});
  width: 100vw;
  position: relative;
  background-color: ${({ theme }) => theme.body};
  display: flex;
  flex-direction: column;
  
`;


// Container styled component
const Container = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  // @media (max-width: 70em) {
  //   width: 85%;
  // }
  // @media (max-width: 48em) {
  //   width: 100%;
  // }
  @media (max-width: 48em) {
    width: 90%;
  }
`;

const PurchaseTitle = styled.h2`
  font-size: ${(props) => props.theme.fontxxl};
  color: ${(props) => props.theme.text};
`;

const ShortDescription = styled.body`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
  margin: 1rem auto;

`;


const Price = styled.p`
font-size: ${(props) => props.theme.fontxl};
color: ${(props) => props.theme.text};
margin: 1rem auto;

`;

const PurchaseSection = styled.section`
  display: flex;
  width: 100%;
  padding: 2em 0;

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: center;
    
  }
`;

const PurchaseDetails = styled.div`
  flex: 0 0 34%;
  text-align: left;
  margin-right: 6%;

  @media (max-width: 48em){
    width: 80%;
    margin-right: 0;
    text-align:center;
    // font-size: ${(props) => props.theme.fontmd};
  }
`;

const Description = styled.body`
  width: 60%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
    margin: 1rem auto;

  @media (max-width: 48em){
    width: 80%;
    text-align:center;

  }
`;

const ModuleChoice = styled.h2`
  cursor: pointer;
  margin-right: 1em;
  margin-left: ${props => props.first ? '0' : '1em'};
  font-size: ${(props) => props.theme.fontl};
  color: ${props => props.isSelected ? props.theme.text : '#797979'};

  @media (max-width: 48em){
    font-size: ${(props) => props.theme.fontlg};
  }
  
`;


const ModulesSection = styled.section`
  display: flex;
  width: 100%;
  padding: 3em 0;

  @media (max-width: 48em) {
    padding: 3em 0;

    flex-direction: column;
    align-items: center;
  }
`;

const ModulesLeft = styled.div`
  width: 40%;
  display: flex;
  justify-content: Left;
  align-items: center;


  @media (max-width: 48em) {
    padding: 2em 0;

    width: 100%;
    justify-content: center;
  }
`;


const ModulesRight = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 48em) {
    margin-bottom: 3em;
    width: 100%;
    align-items: center;
  }
`;

const ModulesTitle = styled.h1`
  font-size: ${(props) => props.theme.fontxl};
  color: ${(props) => props.theme.text};
`;

const ModulesList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  height: 50%;
  padding: 0;
  align-items: center;
  justify-content: left;
  // font-size: ${(props) => props.theme.fontlg};

  // background-color: red;

  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontlg};
    justify-content: center;
  }

`;

const ModuleDescription = styled.body`
  height: 50%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1em;
  align-items: center;
  justify-content: Left;
  color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
  // background-color: orange;

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: center;
    text-align:center;
  }
`;


const GuiSection = styled(ModulesSection)``;

const GuiImage = styled.img`
  max-width: 100%;
  z-index: 1;

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: center;
  }
`;

const HeroSection = styled.section`
  display: flex;
  width: 100%;
`;

// Compatibility styled components
const CompatibilitySection = styled(ModulesSection)``;

const CompatibilityLeft = styled(ModulesLeft)``;

const CompatibilityTitle = styled(ModulesTitle)``;


const CompatibilityBody = styled.div`
width: 60%;
  height: 100%;
  display: flex-start;
  flex-direction: column;

  @media (max-width: 48em) {
    width: 100%;
    align-items: center;
  }
`;

const CompatibilityItem = styled.div`
  display: flex;
  width = 100%;
  justify-content: space-between;
  align-items: center; 

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: flex-start; // Align items to the start on mobile devices
  }
`;


const CompatibilityHeading = styled.h2`
  width = 50%;
  align-items: center;

  font-size: ${(props) => props.theme.fontl};
  color: ${(props) => props.theme.text};
  margin: 1rem 0rem;;
  text-align: left;

  @media (max-width: 48em) {
    width = 100%;
  }

`;

const CompatibilityText = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.textRgba},0.75)`};
  margin: 1rem 0rem;;
  width: 50%; // Adjust as needed to provide space between heading and text
  text-align: left;
  

  @media (max-width: 48em) {
    width: 100%;
    // background-color: red;
  }
`;

const ButtonDemoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media (max-width: 48em) {
    flex-direction: column;
    align-items: center;
  }
`;

const DemoLink = styled.a`
  text-decoration: underline;
  color: ${(props) => props.theme.text};
  cursor: pointer;
  margin-top: 1rem;
  // margin-right: 6.5rem;

  text-align: right;
  // flex-grow: 1;
  text-align: end; // Ensures text is always at the end of the line

  @media (max-width: 48em) {
    flex-direction: column;
    text-align: center;
    display: flex;
    width: 100%;

  }
 
`;

const WaveformPlayersSection = styled(ModulesSection)``;
const WaveformPlayersLeft = styled(ModulesLeft)``;
const WaveformPlayersRight = styled(ModulesRight)``;



const SVGContainer = styled.div`
  position: absolute;
  width: 50%;  
  height: 100%; 
  top: ${props => props.top || '0%'};
  right: ${props => props.right || '0%'};

  fill: linear-gradient(180deg, rgba(158, 236, 217, 0.73) 0%, rgba(47, 184, 255, 0) 100%);

  // background: linear-gradient(180deg, rgba(158, 236, 217, 0.73) 0%, rgba(47, 184, 255, 0) 100%);
  // borderRadius: 9999;

  @media (max-width: 48em) {
    top: ${props => props.mobileTop || props.top || '0%'};
    right: ${props => props.mobileRight || props.right || '0%'};
  }
`;





const GradientCircle = ({ style, scale = 1 }) => {
  return (
    <SVGContainer style={style}>
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="100%" 
        height="100%" 
        viewBox="0 0 171 171" 
        fill="none"
        preserveAspectRatio="xMidYMid meet"
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'center'
        }}
      >
       <circle cx="85.5" cy="85.5" r="85.5" fill="url(#paint0_linear_3_335)" fill-opacity="0.73"/>
        <defs>
          <linearGradient id="paint0_linear_3_335" x1="85.5" y1="0" x2="85.5" y2="90" gradientUnits="userSpaceOnUse">
            <stop stop-color="#9EECD9"/>
            <stop offset="1" stop-color="#2FB8FF" stop-opacity="0"/>
          </linearGradient>
        </defs>
      </svg>
    </SVGContainer>
  );
};





const PlayersContainer = styled.div`

  gap: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start
  flex-wrap: wrap;
  // background: #0019FF;

  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 48em) {
    flex-direction: column;
    grid-gap: 2em;  
      // display: flex;
      width: 100%;
      align-items: center;
  }
 
`;




function handleFastSpring(productPath) {
  window.fastspring.builder.add(productPath);
  window.fastspring.builder.checkout();
}

const ProductsPage = () => {

  const options = {
    autoConfig: true,
    debug: false,
  };

  ReactPixel.init('6972889449388053', options);


  useEffect(() => {
    // Attach the dataPopupClosed function to the window object
    window.dataPopupClosed = (data) => {
      ReactPixel.track('Purchase', {currency: "USD", value: data.total});
    };
  }, []);
  
  
  useScrollToTop();

  const modules = [
    {
      id: 1,
      name: "Reverb",
      description: "Experience the power of the Reverb module. It's equipped with Size, Predelay, Early/Late, and Colour parameters to give you full control over your sound. The Early/Late parameter lets you balance early and late reflections in the reverb tail, while the Colour parameter allows you to darken the reverb tail for a warmer sound."
    },
    {
      id: 2,
      name: "Modulation",
      description: "The Modulation module is designed to bring your sound to life. It features Evolve and Sweep parameters for dynamic sound modulation. The Evolve parameter modulates reflection times, creating an evolving soundscape, while the Sweep parameter introduces radical pitch modulation to the reverb tail."
    },
    {
      id: 3,
      name: "Shimmer",
      description: "Add a touch of magic to your sound with our Shimmer module. It introduces a pitch-shifted feedback path to the reverb, controlled by the Mix and Pitch parameters. This results in an ever-increasing pitched reverb, adding a unique dimension to your audio."
    },
    {
      id: 4,
      name: "Spatial Mode",
      description: "Our Spatial Shimmer module offers versatile rendering options. You can render the output Binaurally, to Stereo, 5.1, or 7.1.2, giving you the flexibility to create immersive soundscapes in a variety of formats."
    },
  ];
  

  const [selectedModule, setSelectedModule] = useState(modules[0]); // Set initial state to the first module

  return (
    <Section>
       <Helmet>
        <title>Sonara DSP - Spatial Shimmer </title>
        <meta name="description" content="Venture into the realm of spatial audio with Spatial Shimmer – designed to effortlessly create enveloping and evolving reverbs." />
        {/* Add any other meta tags you want here */}
      </Helmet>
      <Container>
        <HeroSection>
          <AnimatedHeader/>
          <GuiImage src={SpatialShimmerImage} alt="GUI image" />
        </HeroSection>

        <PurchaseSection>
          <PurchaseDetails>
            <PurchaseTitle>Spatial Shimmer</PurchaseTitle>
            <ShortDescription>Enveloping Multichannel & Binaural Reverb</ShortDescription>
            <Price>$9.99</Price>
            {/* <Button text="Coming Soon" link="/products" /> */}
            {/* <Button text="Buy Now" fastSpring={true} productPath="spatial-shimmer" /> */}
            {/* <Button text="Demo" fastSpring={true} productPath="spatial-shimmer" /> */}

            <ButtonDemoWrapper>
              <Button text="Buy Now" fastSpring={true} productPath="spatial-shimmer" />
              <DemoLink onClick={() => handleFastSpring("spatial-shimmer-demo")}>Try The Demo</DemoLink>
            </ButtonDemoWrapper>
            </PurchaseDetails>
          
          <Description>
            {/* <SubText> */}
             Spatial Shimmer is an innovative reverb plugin which allows for the creation of enveloping reverberant effects 
             from environments which approach the characteristics of a physical room to etheral, and evolving ambiances. 
             Spatial Shimmer offers classic reverb controls and elevates them to a new dimension with shimmer and modulation modules. 
             The highlight is the choice of  binaurally processing the output, rendering to a multichannel configuration or to conventional stereo.
             Spatial Shimmer is the ideal plugin for forward-thinking producers who are eager to venture into the realm of spatial audio. 
             With its innovative reverberant effects, it offers a creative way to enhance your mixes and propel them into the world of immersive audio.
           {/* </SubText> */}
          </Description>
        </PurchaseSection>
        
        <Divider />

        <GuiSection>
          <ModulesLeft>
            <ModulesTitle>GUI</ModulesTitle>
          </ModulesLeft>
          <ModulesRight>
            <GuiImage src={SpatialShimmerImage} alt="GUI image" />
          </ModulesRight>
        </GuiSection>

        <Divider />

        <ModulesSection>
          <ModulesLeft>
            <ModulesTitle>Modules</ModulesTitle>
          </ModulesLeft>
          <ModulesRight>
            <ModulesList>
              {modules.map((module, index) => (
                <ModuleChoice 
                  key={index} 
                  first={index === 0}
                  isSelected={module.id === selectedModule?.id} 
                  onClick={() => setSelectedModule(module)}
                >
                  {module.name}
                </ModuleChoice>
              ))}
            </ModulesList>
            {selectedModule && (
              <ModuleDescription>
                {selectedModule.description}
              </ModuleDescription>
            )}
          </ModulesRight>
        </ModulesSection>

        <Divider />

        <WaveformPlayersSection>
        
          <WaveformPlayersLeft>
            <ModulesTitle>Samples</ModulesTitle>
          </WaveformPlayersLeft>

          <WaveformPlayersRight>
          <PlayersContainer>

          <SVGContainer top='-5%' right='65%' mobileTop='-35%' mobileRight='17%'>
            <GradientCircle scale={1.3}/>
          </SVGContainer>

          <SVGContainer top='40%' right='40%' mobileTop='-16%' mobileRight='55%'>
            <GradientCircle scale={1.1} />
          </SVGContainer>

          <SVGContainer top='-17%' right='20%' mobileTop='18%' mobileRight='10%'>
            <GradientCircle scale={1} />
          </SVGContainer>

          <SVGContainer top='35%' right='-2%' mobileTop='50%' mobileRight='60%'>
            <GradientCircle scale={0.8} />
          </SVGContainer>



              <AudioPlayerProvider>

              <BeforeAndAfterWaveform
                      title="Synth"
                      trackUrlBefore={synthBefore}
                      trackBeforeID="synthBefore"
                      trackUrlAfter={synthAfter}
                      trackAfterID="synthAfter"
                    />
              <BeforeAndAfterWaveform
                      title="Voice"
                      trackUrlBefore={voiceBefore}
                      trackBeforeID="voxBefore"
                      trackUrlAfter={voiceAfter}
                      trackAfterID="voxAfter"
                    />   
              <BeforeAndAfterWaveform
                      title="Piano"
                      trackUrlBefore={pianoBefore}
                      trackBeforeID="pianoBefore"
                      trackUrlAfter={pianoAfter}
                      trackAfterID="pianoAfter"
                    />  
            </AudioPlayerProvider>
            </PlayersContainer>
          </WaveformPlayersRight>
        
        </WaveformPlayersSection>

        <Divider />

        <CompatibilitySection>
          <CompatibilityLeft>
            <CompatibilityTitle>Compatibility</CompatibilityTitle>
          </CompatibilityLeft>

          <CompatibilityBody>

            <CompatibilityItem>
              <CompatibilityHeading>Plugin format</CompatibilityHeading>
              <CompatibilityText>VST3 / AU </CompatibilityText>
            </CompatibilityItem>

            <CompatibilityItem>
              <CompatibilityHeading>Memory</CompatibilityHeading>
              <CompatibilityText>8GB RAM+</CompatibilityText>
            </CompatibilityItem>

            <CompatibilityItem>
              <CompatibilityHeading>Processor</CompatibilityHeading>
              <CompatibilityText>Intel Core i5 CPU or equivalent+</CompatibilityText>
            </CompatibilityItem>

            <CompatibilityItem>
              <CompatibilityHeading>Graphics</CompatibilityHeading>
              <CompatibilityText> OpenGL 3.6+, 2GB+ GPU, such as AMD Radeon Pro 5300M, or equivalent </CompatibilityText>
            </CompatibilityItem>

            <CompatibilityItem>
              <CompatibilityHeading>Operating System</CompatibilityHeading>
              <CompatibilityText>
                Mac OS X: 10.9+<br />
                Windows: 10+ <br />
              </CompatibilityText>
            </CompatibilityItem>
{/* ben = intel iris plus graphics 655, will Intel UHD Graphics 630 */}
            

            <CompatibilityItem>
              <CompatibilityHeading>DAW support</CompatibilityHeading>
              <CompatibilityText>
              Most VST3 / AU - compatible hosts running on a supported operating system <br />
              64-bit support only   
              </CompatibilityText>
            </CompatibilityItem>

          
          
            
          </CompatibilityBody>
        </CompatibilitySection>



      </Container>
    </Section>
  );
};

export default ProductsPage;
