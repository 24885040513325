import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ReactPixel from 'react-facebook-pixel';



const Btn = styled.button`
  display: inline-block;
  background-color: ${props => props.theme.text};
  color: ${props => props.theme.body};
  outline: none;
  border: none;
  font-size: ${props => props.theme.fontsm};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  box-sizing: border-box;

  &:hover {
    transform: scale(0.9);
  }

  &::after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border: 2px solid ${props => props.theme.text};
    border-radius: 50px;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease;
    box-sizing: border-box;
  }

  &:hover::after {
    width: calc(100% + 15px);  // account for border width
    height: calc(100% + 15px);  // account for border width
    transform: translate(-50%, -50%) scale(1);
  }
`




const Button = ({text, link, fastSpring, productPath, onClick}) => {
  const handleOnClick = () => {
    window.fastspring.builder.add(productPath);
    window.fastspring.builder.checkout();
    if (onClick) onClick();
  };

  const handleNavLinkClick = () => {
    if (onClick) onClick(); 
  }

  if (fastSpring) {
    return (
      <Btn onClick={handleOnClick}>
        {text}
      </Btn>
    )
  } else {
    return (
      <Link to={link} onClick={handleNavLinkClick}>
        <Btn>
          {text}
        </Btn>
      </Link>
    )
  }
}

export default Button